<script setup lang="ts">
import { DS_ICONS, DsPageHeader } from '@dreamshaper/ds-ui'
import DsSidebar from '../components/DsSidebar/DsSidebar.vue'

  type LayoutProps = {
    classContainer?: string | Record<string, boolean>
    classFooter?: string | Record<string, boolean>
  }

useHead({
  htmlAttrs: {
    class: 'light',
  },
})

const config = useRuntimeConfig()

const { org, customer } = useUser()
const route = useRoute() as any

const getName = () => {
  if (route.name?.includes('org')) {
    return org.value?.subdomain
  }

  return customer.value?.name
}

const getType = () => {
  if (route.name?.includes('org')) {
    return 'org'
  }

  return 'customer'
}

const pageName = ref<string>(getName() || '')
const pageType = ref<'org' | 'customer'>(getType())

const previousRoute = ref<string | null>(null)
const currentFullPath = ref<string | null>(null)

const props = defineProps<LayoutProps>()

const typeNavigation: any = config.public.env

const defaultRoutes = computed(() => {
  return (
    route.path === '/org'
    || route.path === '/customer'
    || route.path === '/customer/create'
    || route.path === '/user'
    || route.name === 'user-userId-edit'
    || route.name === 'user-userId'
    || route.path === '/home'
  )
})

const structure = computed<any[]>(() => {
  const optionsStructure = []

  if (defaultRoutes.value) {
    optionsStructure.push({
      label: 'Organizations',
      childs: [],
      component: resolveComponent('NuxtLink'),
      componentProps: {
        to: '/org',
      },
      currentPage: false,
    })

    optionsStructure.push({
      label: 'Customers',
      childs: [],
      component: resolveComponent('NuxtLink'),
      componentProps: {
        to: '/customer',
      },
      currentPage: false,
    })

    optionsStructure.push({
      label: 'Users',
      childs: [],
      component: resolveComponent('NuxtLink'),
      componentProps: {
        to: '/user',
      },
      currentPage: false,
    })
  }

  if (route.name?.includes('org-orgId')) {
    if (route.name === 'org-orgId-home') {
      optionsStructure.unshift({
        label: 'Admin Home',
        icon: DS_ICONS.ChevronLeft,
        childs: [],
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: '/home',
        },
        currentPage: false,
      })
    } else {
      optionsStructure.unshift({
        label: 'Org Dashboard',
        icon: DS_ICONS.ChevronLeft,
        childs: [],
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: `/org/${route.params.orgId}/home`,
        },
        currentPage: false,
      })
    }

    optionsStructure.push({
      label: 'Admin OPS',
      childs: [
        {
          label: 'Org Settings',
          component: resolveComponent('NuxtLink'),
          componentProps: {
            to: `/org/${route.params.orgId}/edit`,
          },
          currentPage: false,
        },
        {
          label: 'Users',
          component: resolveComponent('NuxtLink'),
          componentProps: {
            to: `/org/${route.params.orgId}/user`,
          },
          currentPage: false,
        },
        {
          label: 'Projects',
          component: resolveComponent('NuxtLink'),
          componentProps: {
            to: `/org/${route.params.orgId}/project`,
          },
          currentPage: false,
        },
        {
          label: 'Clusters',
          childs: [
            {
              label: 'All',
              component: resolveComponent('NuxtLink'),
              componentProps: {
                to: `/org/${route.params.orgId}/cluster`,
              },
              currentPage: false,
            },
            {
              label: 'Categories',
              component: resolveComponent('NuxtLink'),
              componentProps: {
                to: `/org/${route.params.orgId}/cluster/categories`,
              },
              currentPage: false,
            },
          ],
          currentPage: false,
        },
        {
          label: 'Evaluation',
          component: resolveComponent('NuxtLink'),
          componentProps: {
            to: `/org/${route.params.orgId}/evaluation`,
          },
          currentPage: false,
        },
        {
          label: 'Reports',
          badgeText: 'In Dev',
          component: resolveComponent('NuxtLink'),
        },
      ],
      currentPage: false,
    })

    if (org.value?.multipath_support === true) {
      optionsStructure.push({
        label: 'Ds Builder',
        childs: [
          {
            label: 'Paths',
            component: resolveComponent('NuxtLink'),
            componentProps: {
              to: `/org/${route.params.orgId}/builder/path`,
            },
            currentPage: false,
          },
          {
            label: 'Outputs',
            component: resolveComponent('NuxtLink'),
            componentProps: {
              to: `/org/${route.params.orgId}/builder/output`,
            },
            currentPage: false,
          },
          {
            label: 'Resources',
            badgeText: 'In Dev',
            component: resolveComponent('NuxtLink'),
          },
        ],
        currentPage: false,
      })
    }
  }

  if (route.name?.includes('customer-customerId')) {
    if (route.name === 'customer-customerId-home') {
      optionsStructure.unshift({
        label: 'Customer List',
        icon: DS_ICONS.ChevronLeft,
        childs: [],
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: '/customer',
        },
      })
    } else {
      optionsStructure.unshift({
        label: 'Customer Dashboard',
        icon: DS_ICONS.ChevronLeft,
        childs: [],
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: `/customer/${customer.value?.id}/home`,
        },
      })
    }

    optionsStructure.push({
      label: 'Customer Settings',
      childs: [],
      component: resolveComponent('NuxtLink'),
      componentProps: {
        to: `/customer/${customer.value?.id}/edit`,
      },
      currentPage: false,
    })

    optionsStructure.push({
      label: 'Context',
      childs: [],
      component: resolveComponent('NuxtLink'),
      componentProps: {
        to: `/customer/${customer.value?.id}/context`,
      },
      currentPage: false,
    })

    optionsStructure.push({
      label: 'Period',
      childs: [],
      component: resolveComponent('NuxtLink'),
      componentProps: {
        to: `/customer/${customer.value?.id}/period`,
      },
      currentPage: false,
    })

    optionsStructure.push({
      label: 'Report',
      childs: [],
      component: resolveComponent('NuxtLink'),
      componentProps: {
        to: `/customer/${customer.value?.id}/report`,
      },
      currentPage: false,
    })
  }

  const getRouteName = (routeParams: string) => {
    if (!routeParams) return routeParams

    const routeNameSplit = routeParams?.split('/')

    if (routeParams.includes('edit') || routeParams.includes('home') || routeParams.includes('info')) {
      return routeNameSplit.splice(0, routeNameSplit.length - 2).join(`/`)
    } else if (routeParams.includes('create')) {
      return routeNameSplit.splice(0, routeNameSplit.length - 1).join(`/`)
    }

    return routeParams
  }

  optionsStructure.forEach((item) => {
    const currentPageRoute = currentFullPath.value as string
    const itemRoute = item.componentProps?.to as string

    const isCurrentPageOrParent = (routeA: string, routeB: string) => {
      const routeFormatted = getRouteName(routeA)
      return routeFormatted === routeB || routeA === routeB
    }

    if (isCurrentPageOrParent(currentPageRoute, itemRoute)) {
      item.currentPage = true
      let parent = item

      while (parent) {
        parent.currentPage = true
        parent = (parent as any).parent
      }
    } else if (item.childs) {
      item.childs.forEach((child) => {
        const childRoute = child.componentProps?.to as string

        if (isCurrentPageOrParent(currentPageRoute, childRoute)) {
          child.currentPage = true
          let parent = item

          while (parent) {
            parent.currentPage = true
            parent = (parent as any).parent
          }
        } else if ((child as any).childs) {
          (child as any).childs.forEach((grandchild: any) => {
            const grandchildRoute = grandchild.componentProps?.to

            if (isCurrentPageOrParent(currentPageRoute, grandchildRoute)) {
              grandchild.currentPage = true
              let parent = child

              while (parent) {
                parent.currentPage = true
                parent = (parent as any).parent
              }

              let grandparent = item
              while (grandparent) {
                grandparent.currentPage = true

                grandparent = (grandparent as any).parent
              }
            }
          })
        }
      })
    }
  })

  return optionsStructure
})

const shouldRenderPageHeader = computed(() => {
  const isOrgRoute = route.name?.includes('org-orgId')
  const isCustomerRoute = route.name?.includes('customer-customerId')

  const hiddenRoutes = [
    'org-orgId-edit',
    'customer-customerId-edit',
  ]

  const isHiddenRoute = hiddenRoutes.some(hiddenRoute =>
    route.name?.includes(hiddenRoute),
  )

  return (isOrgRoute || isCustomerRoute) && !isHiddenRoute
})

onMounted(() => {
  previousRoute.value = route.fullPath
  currentFullPath.value = route.fullPath
})

watch(
  () => route.fullPath,
  (newRoute, oldRoute) => {
    previousRoute.value = oldRoute
    currentFullPath.value = newRoute
  },
)

watch([() => route.name, org, customer], () => {
  pageName.value = getName() || ''
  pageType.value = getType()
}, { deep: true, immediate: true })
</script>

<template>
  <main style="background-color: #e9eef3">
    <section class="flex min-h-screen h-full">
      <DsSidebar
        name="Admin"
        :type="typeNavigation"
        :structure="structure"
      >
        <div class="w-full pl-4 pr-2">
          <DsPageHeader
            v-if="shouldRenderPageHeader"
            v-memo="[pageName, pageType]"
            :page-name="pageName"
            :type="pageType"
          />
        </div>
      </DsSidebar>

      <section class="flex flex-col w-full ml-[12.5rem]">
        <section class="flex flex-col w-full h-full items-center gap-14">
          <Header />

          <Container :class="props.classContainer">
            <slot name="container" />
          </Container>
        </section>
      </section>
    </section>

    <div id="settings-root" />
  </main>
</template>
